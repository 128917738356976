.summaryContainer {
  flex: 1;

  & .summaryBody {
    padding: 12px 0;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 12px;
    flex-shrink: 1;

    & .summarySuperSection {
      display: flex;
      flex-wrap: wrap;
      gap: 12px;

      & .summarySection {
        display: flex;
        flex-direction: column;
        gap: 8px;
        flex: 1;
        // width: 100%;
      }
    }
  }
}
