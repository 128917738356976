.templateContainer {
  border-radius: 4px;
  max-width: 600px;
  overflow: hidden;
  box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.3);

  .redbar {
    height: 50px;
    background-color: #c13401;
    display: flex;
    align-items: center;
    justify-content: center;

    min-height: 50px;
    background-color: #c13401;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    &.title {
      font-size: 1.5em;
      font-weight: bold;
      padding: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
    &.bottom {
      font-size: 0.8em;
      justify-content: flex-end;
      text-transform: uppercase;
      padding: 0 24px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
  .message {
    padding: 32px 24px;
    text-align: center;
    background-color: #fff;
    font-size: 16px;
    line-height: 1.4em;
  }
  .backGroundImg {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    padding-bottom: 33.67%; /* kiping the ratio of the images */
    position: relative;
  }
}
