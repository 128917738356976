.pageWrap {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100vh;

  & > .pageHeader {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: flex-end;

    & img {
      width: 100%;
      max-width: 500px;
      height: auto; /* Ajuste la hauteur en fonction de la largeur */
      display: block; /* �vite l'espacement en bas de l'image */
    }
  }

  & > .pageBody {
    flex: 1;
    padding: 8px 24px 48px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    @media (max-width: 480px) {
      padding: 8px 16px 48px;
    }

    .summaryAndWinner {
      flex: 1;
      display: flex;
      align-items: flex-start;
      gap: 24px;
      flex-wrap: wrap;

      .summary {
        @media (max-width: 940px) {
          width: 100%;
        }
      }

      .winner {
        flex: 1;
      }
    }

    .bodySection {
      border: 1px solid #eee;
      border-radius: 8px;
      padding: 12px;
      background: #fafafa;
      // height: 370px;
    }

    & .titleWrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
    }

    & .filterSection {
      position: relative;

      & .loading {
        position: absolute;
        bottom: -30px;
        right: 0;
        left: 0;

        .loadingPercent {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          color: #17a0a1;
          font-size: 1.1em;
          margin-bottom: 6px;
        }
      }
    }
  }

  .sectionTopBar {
    font-size: 1.5em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-items: flex-start;
    gap: 8px;
    @media (max-width: 538px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
