.filterWrapper {
  position: relative;
  padding: 72px 0 24px 0;
  box-sizing: border-box;
  display: flex;
  height: 100%;

  & > .closeIcon {
    cursor: pointer;
    position: absolute;
    top: 8px;
    left: 0;
    padding: 16px;
  }

  & .filterBodyWrap {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;

    & .filterBody {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 32px;
      padding-bottom: 32px;
      overflow-y: auto;

      & .filterSection {
        // border: 1px solid #f2f2f2;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        gap: 8px;

        & .filterSectionHeader {
          position: relative;
          font-size: 1.4em;
        }
      }
    }
  }

  .filterBody,
  .submitButton {
    padding: 0 18px;
    box-sizing: border-box;
  }
}

.badgeContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;

  & .badge {
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
  }
}
