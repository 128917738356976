.winnersContainer {
  display: flex;
  flex-direction: column;
  height: 364px;

  .winnerLinesContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    max-height: 310px;
    overflow-x: auto;
    padding: 12px 0;
    flex: 1;
    box-sizing: border-box;

    // scrollbar-gutter: stable;
    &::-webkit-scrollbar {
      height: 8px; /* Adjust the height of the scrollbar (optional) */
    }

    &::-webkit-scrollbar-thumb {
      background-color: #bbb; /* Custom scrollbar thumb color */
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #999; /* Change color on hover */
    }

    .winnerLine {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;
      padding: 4px 12px;
      background-color: #eee;
      border-radius: 6px;
      width: 230px;
      height: 24px;

      .winnerLabel {
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .sectionActions {
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 6px;
    padding-top: 6px;

    @media (max-width: 479px) {
      align-items: flex-end;
      gap: 4px;
      align-items: center;
      width: 100%;
    }
  }

  .winnersNumber {
    min-width: 40px;
    text-align: center;
  }
  .bottomBar {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #ccc;
    padding: 10px 12px;
    background: #f9f9f9;
    position: sticky;
    bottom: 0;
  }
  .topBar {
    font-size: 1.2em;
    display: flex;
    justify-content: space-between;
    align-items: start;
    border-bottom: 1px solid #ccc;
    padding: 10px 12px;
    background: #f9f9f9;
    position: sticky;
    top: 0;
  }
}
