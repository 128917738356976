.avatarContainer {
  position: relative;
  width: 80px;
  height: 80px;
  & .avatarIcon {
    position: absolute;
    bottom: -8px;
    right: -8px;
    color: #16a0a1;
    background-color: #daf0f0;
    border-radius: 50%;
    border: 3px solid #fff;
    padding: 4px;
  }
}
.avatarWrap {
  position: relative;
  padding: 6px 0;

  & > .imgNbr {
    position: absolute;
    top: 0;
    right: -6px;
    background-color: #ddd;
    border-radius: 50%;
    font-size: 0.8em;
    box-sizing: border-box;
    font-weight: bold;
    height: 30px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid #f7f7fa;
  }
}
img.avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  & > .imgNbr {
    background-color: #daf0f0;
    color: #999;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 50%;
    height: 30px;
    width: 30px;
  }
}

.cheersCard {
  cursor: pointer;
  display: flex;
  gap: 16px;
  align-items: center;
  margin-bottom: 24px;

  & .cheersCardLeft {
    display: flex;
    gap: 16px;
    align-items: flex-start;
    flex: 1;
    overflow: hidden;
  }

  & .cheersCardContent {
    overflow: hidden;
    flex: 1;
    color: #999;
    font-size: 1.1em;
    line-height: 1.5em;
    & .cheersCardTitle {
      color: initial;
      font-weight: bold;
    }
    & > div {
      white-space: nowrap; /* Emp�che le retour � la ligne */
      overflow: hidden; /* Cache le contenu d�bordant */
      text-overflow: ellipsis;
    }
  }
  & .chevronRight {
    color: #999;
  }
}

.detailsWrap {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;

  & > .closeIcon {
    cursor: pointer;
    position: absolute;
    top: 8px;
    left: 0;
    padding: 16px;
  }

  & > .columnPart {
    display: flex;
    flex-direction: column;
    gap: 32px;
    justify-content: flex-start;
    font-size: 1.1em;

    & .detailsShort {
      line-height: 1.6em;
      & .timedate {
        display: flex;
        align-items: center;
        gap: 8px;
        color: #999;
      }
      & .category {
        color: #e22d18;
        font-weight: bold;
        text-transform: uppercase;
      }
    }
    & .detailsTitle {
      font-size: 1.5em;
      margin-bottom: 24px;
    }
  }
  & .detailsHeaderContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 24px;
    font-size: 1.05em;
  }
}

.overflowHidden {
  overflow: hidden;
}
.nowrap {
  display: block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.postCardWrapper {
  position: relative;

  & > .closeIcon {
    position: absolute;
    top: 0;
    left: 0;
    padding: 12px;
    display: flex;
    align-items: center;
    color: #fff;
    cursor: pointer;
  }
  & > .postCardControles {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    padding: 12px;
  }
}

.helpButton {
  position: absolute;
  top: 32px;
  right: 32px;
}
