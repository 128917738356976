.firstStepWrapper {
  position: relative;
  padding: 90px 24px 24px 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 32px;
  background-color: #fff;

  @media (min-width: 480px) {
    min-width: 480px;
  }

  & > .closeIcon {
    cursor: pointer;
    position: absolute;
    top: 8px;
    left: 0;
    padding: 16px;
  }
  & > .title {
    font-size: 2em;
  }
  & > .step1type {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
  }
  & > .step2type {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
  }
  & .step2buttonsWrap {
    display: flex;
    justify-content: center;

    & > .step2buttons {
      max-width: 430px;
      display: flex;
      align-items: center;
      gap: 32px;
      width: 100%;
    }
  }

  & .imageSquare,
  & .imageRecipient {
    position: relative;
    cursor: pointer;
    width: 50%;
    height: 0;
    padding-top: 50%;
    background-size: cover;
    background-position: center;
    border-radius: 10px;
  }
  & .imageRecipient {
    width: 100%;
    padding-top: 27%;
    height: auto;
  }

  & .individualImg {
    background-image: url("./../../../assets/img/unchecked-individual.jpg");
  }
  & .individualImg.active {
    background-image: url("./../../../assets/img/checked-individual.jpg");
  }
  & .groupeImg {
    background-image: url("./../../../assets/img/unchecked-groupe.jpg");
  }
  & .groupeImg.active {
    background-image: url("./../../../assets/img/checked-groupe.jpg");
  }

  & .primoImg {
    background-image: url("./../../../assets/img/unchecked-primo.jpg");
  }
  & .primoImg.active {
    background-image: url("./../../../assets/img/checked-primo.jpg");
  }
  & .primoImg.groupe {
    background-image: url("./../../../assets/img/unchecked-primos.jpg");
  }
  & .primoImg.groupe.active {
    background-image: url("./../../../assets/img/checked-primos.jpg");
  }

  & .externalImg {
    background-image: url("./../../../assets/img/unchecked-external.jpg");
  }
  & .externalImg.active {
    background-image: url("./../../../assets/img/checked-external.jpg");
  }
  & .externalImg.groupe {
    background-image: url("./../../../assets/img/unchecked-externals.jpg");
  }
  & .externalImg.groupe.active {
    background-image: url("./../../../assets/img/checked-externals.jpg");
  }

  & .mixedImg {
    background-image: url("./../../../assets/img/unchecked-mixed.jpg");
  }
  & .mixedImg.active {
    background-image: url("./../../../assets/img/checked-mixed.jpg");
  }
}
.formContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  max-width: 500px;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 32px;

  & > .formCore {
    font-size: 1.2em;
    padding-bottom: 46px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    flex: 1;

    & > .formTitle {
      font-size: 1.5em;
      margin-bottom: 24px;
    }
    & > .stepNumber {
      text-transform: uppercase;
      color: #e22d18;
      font-weight: bold;
    }
    // & > * {
    //   margin-bottom: 25px;
    // }
  }

  & > .formCongratsHeaderImg {
    width: 160px;
    height: 160px;
    object-fit: cover;
    padding: 64px 0 42px;
    &.round {
      border-radius: 50%;
    }
  }
}
.flexH {
  display: flex;
  align-items: center;
  gap: 0.5em;
}
