.container {
  max-width: 500px;
  margin-right: auto;
  margin-left: auto;

  & .pageTitle {
    font-size: 4em;
    margin-bottom: 24px;
  }
  & .section {
    padding: 24px 0;
    font-size: 1.2em;
    line-height: 1.3;

    & .mail {
      text-decoration: none;
      color: red;
    }

    & > .sectionTitle {
      font-weight: bold;
      font-size: 1.2em;
      margin-bottom: 1em;
    }
    & > .subSection {
      margin-bottom: 32px;
    }
    & > .subSectionHorizental {
      margin-bottom: 32px;
      display: flex;
      align-items: center;
      gap: 32px;

      & > .iconImg {
        width: 86px;
        height: 86px;
      }
      & > .desc {
        flex: 1;
      }
    }
  }
  & .fullWithImg {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 8px;
  }
}
