.trendsChartsContainer {
  position: relative;
  min-width: 300px;
  flex: 1;
  display: flex;
  flex-direction: column;

  .filtersContainer {
    position: absolute;
    top: 8px;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 32px;
  }
  .dataBody {
    padding: 24px 0;
  }
  .sectionActions {
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    padding-top: 6px;
    flex-wrap: wrap-reverse;
    justify-content: end;

    @media (max-width: 479px) {
      align-items: flex-end;
      gap: 4px;
      align-items: center;
      width: 100%;
    }
  }
}
