.unknown-error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
}

.unknown-error-heading {
  font-size: 6rem;
  margin-bottom: 1rem;
}

.unknown-error-title {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  text-transform: uppercase;
}

.unknown-error-message {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.unknown-error-button {
  font-size: 1.2rem;
  color: #000;
  text-decoration: none;
  border: 2px solid #000;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.unknown-error-button:hover {
  background-color: #000;
  color: #fff;
}
