.filterWrapper {
  position: relative;
  padding: 72px 24px 24px 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 32px;

  & > .closeIcon {
    cursor: pointer;
    position: absolute;
    top: 8px;
    left: 0;
    padding: 16px;
  }

  & .switchContainer {
    width: 120px;
  }
}

.badgeContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;
  padding-bottom: 10px;

  & .badge {
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
  }
}
