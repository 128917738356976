.summaryLine {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1px;
  position: relative;
  padding-right: 33px;
  width: 100%;
  box-sizing: border-box;

  .keyVal {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 0 4px;
    box-sizing: border-box;
    min-width: 200px;
    width: 100%;

    .label {
      text-align: left;
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .summaryLineIcon {
    position: absolute;
    right: 0;
  }
  button {
    padding: 1px 6px;
  }
}
// .notActive button {
//   border-color: #d0d0d0;
// }
