.pageWrap {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  overflow-y: auto;

  & .currentFilter {
    padding: 12px 24px;
    /* background-color: #f7f7f7; */
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #eee;
  }
}

.pageHeader {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: flex-end;
}

.pageHeader img {
  width: 100%;
  max-width: 500px;
  height: auto; /* Ajuste la hauteur en fonction de la largeur */
  display: block; /* �vite l'espacement en bas de l'image */
}
.titleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

.homeTitleWrap {
  padding: 25px;
}

.homeContent {
  flex-grow: 1;

  & > .tabulation {
    display: flex;
    margin: 0 24px;
    gap: 24px;
    & > .tabItem {
      cursor: pointer;
      text-transform: uppercase;
      padding: 12px 0;
      &.active {
        font-weight: bold;
        border-bottom: 4px solid #e22d18;
      }
    }
  }

  @media (min-width: 480px) {
    padding-right: 24px;
  }
}

.cheersListWrapper {
  min-height: 100%;
  padding: 24px;
  background-color: #f7f7f7;
  border-top-right-radius: 90px;
  position: relative;

  & .feedsNumber {
    color: #999;
    position: absolute;
    top: -12px;
    left: -14px;
    padding: 4px 12px 4px 24px;
    background-color: #fff;
    font-size: 0.8em;
    border-radius: 12px;
    border: 1px solid #eee;
  }
}

.startWrap {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  padding: 24px;
  box-sizing: border-box;
  height: 100%;

  & .startPgTitleWrap {
    margin-bottom: 46px;

    & .startPgTitle {
      font-size: 4em;
      line-height: 1em;
      margin-bottom: 12px;
    }
    & .startPgSubTitle {
      font-size: 1.4em;
      text-transform: uppercase;
      font-weight: 600;
      color: #e22e18;
    }
  }

  & .startPgBody {
    font-size: 1.4em;
    line-height: 1.3em;
    flex: 1;
  }
  & .startPgPrevNext {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
    font-weight: 600;
    font-size: 21px;

    & .startPgDots {
      flex: 1;
      display: flex;
      justify-content: center;
    }
    & .PrevNextButton {
      display: flex;
      align-items: center;
      color: #e22e18;
      gap: 8px;
      cursor: pointer;
      min-width: 72px;
    }
    & .dotWrap {
      display: flex;
      gap: 10px;

      & .dot {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        display: inline-block;
      }
    }
  }

  & .redCheers {
    color: red;
  }
}

.noneReceived {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  font-size: 1.3em;
  text-align: center;

  & > img {
    height: 150px;
    width: 150px;
  }
}

.notFoundPageContainer {
  text-align: center;
  padding: 50px;

  .title {
    font-size: 48px;
    margin: 0 0 20px;
  }

  .text {
    font-size: 18px;
    margin: 0 0 30px;
  }

  .link {
    font-size: 16px;
    color: #007bff;
    text-decoration: none;
  }
}
